<template>
  <div class="container" id="content">
    <div class="title">
      <div class="title-top">
        <div class="el-title-item" style="width: 100%;">
          <el-form ref="form" label-width="80px" style="width: 100%;justify-content: space-between;">
            <el-form-item label="" style="margin-right: 7vh;">
              <el-button type="success" size="small" @click="addOption">新增</el-button>
            </el-form-item>
            <!-- <el-form-item label="" style="margin-left: 2vw">
              <el-input v-model="searchName" placeholder="请输入名称"></el-input>
            </el-form-item> -->
          </el-form>
        </div>
        <!-- <el-button style="margin-left: 1vw;" type="success" size="small" @click="search">搜索</el-button> -->
      </div>
    </div>
    <el-table
        v-loading="loading"
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%; margin-top: 1vh;">
      <el-table-column prop="sbbh" label="无人机编号" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="sbmc" label="无人机名称" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="fxms" label="飞行模式" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="fxlx" label="飞行路线" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="fxlc" label="飞行里程" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="fxsj" label="飞行时间" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="zxr" label="执行人" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column prop="rwrq" label="任务日期" show-overflow-tooltip align="center"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <!-- <el-button
            type="primary"
            size="small"
            icon="el-icon-document"
            @click="details(scope.row)"
          ></el-button> -->
          <el-button
            type="primary"
            size="small"
            icon="el-icon-edit"
            @click="edits(scope.row)"
          ></el-button>
          <el-button
            type="primary"
            size="small"
            icon="el-icon-delete"
            @click="deletes(scope.row.id)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="block pagingBox">
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="page"
          :page-sizes="[10, 20, 30, 40]"
          :page-size="size"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <!--    编辑-->
    <el-dialog title="" :visible.sync="messageBox" width="31vw" class="addAlameBox">
      <div slot="title" class="header-title" style="position: relative">
        <span class="title-name"></span>
        <span class="title-age">{{ formTitle }}</span>
        <span style=" position: absolute; top: 50%; transform: translateY(-50%); right: 0px; font-size: 2.22vh;"></span>
      </div>
      <div class="addAlaForm">
        <el-form ref="addForm" :rules="rules" :model="addForm" label-width="10vw">
          <el-form-item label="无人机编号" prop="sbbh">
            <el-select v-model="addForm.sbbh" clearable filterable placeholder="请选择无人机编号" @change="sbbhChange" @clear="sbbhClear">
              <el-option
              v-for="item in optionListBH"
              :key="item.bh"
              :label="item.bh"
              :value="item.bh">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="无人机名称" prop="sbmc">
            <el-input disabled v-model="addForm.sbmc" placeholder="请输入无人机名称"></el-input>
          </el-form-item>
          <el-form-item label="飞行模式" prop="fxms">
            <el-input v-model="addForm.fxms" placeholder="请输入飞行模式"></el-input>
          </el-form-item>
          <el-form-item label="飞行路线" prop="fxlx">
            <el-select v-model="addForm.fxlx" filterable multiple collapse-tags placeholder="请选择飞行路线">
              <el-option
              v-for="item in optionListLX"
              :key="item.id"
              :label="item.mc"
              :value="item.mc">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="飞行里程" prop="fxlc">
            <el-input v-model="addForm.fxlc" placeholder="请输入飞行里程"></el-input>
          </el-form-item>
          <el-form-item label="飞行时间" prop="fxsj">
            <el-input v-model="addForm.fxsj" placeholder="请输入飞行时间"></el-input>
          </el-form-item>
          <el-form-item label="执行人" prop="zxr">
            <el-input v-model="addForm.zxr" placeholder="请输入执行人"></el-input>
          </el-form-item>
          <el-form-item label="任务日期" prop="rwrq">
            <!-- <el-input v-model="addForm.rwrq" placeholder="请输入任务日期"></el-input> -->
            <el-date-picker
                v-model="addForm.rwrq"
                type="date"
                placeholder="选择任务日期"
                value-format="yyyy-MM-dd">
            </el-date-picker>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer" v-show="showFlag">
          <el-button type="info" @click="messageBox = false">取消</el-button>
          <el-button type="primary" @click="saveForm('addForm')">确定</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'sensorManagement',
  data() {
    const validateLogoFxlc = (rule, value, callback) => {
      // 只能输入数字和小数点
      const regex = /^[\d.]*$/;
      if (!this.addForm.fxlc) {
        callback(new Error('飞行里程不可为空'))
      } else if (!regex.test(value)) {
        callback(new Error('请输入输入数字和小数点'))
      } else {
        callback()
      }
    }
    const validateLogoFxsj = (rule, value, callback) => {
      // 只能输入数字和小数点
      const regex = /^[\d.]*$/;
      if (!this.addForm.fxlc) {
        callback(new Error('飞行时间不可为空'))
      } else if (!regex.test(value)) {
        callback(new Error('请输入输入数字和小数点'))
      } else {
        callback()
      }
    }
    return {
      optionListBH: [],
      optionListLX: [],
      tableData: [],
      page: 1,
      size: 10,
      total: 0,
      loading: false,
      ids: 0,
      searchName: '',
      addForm: {},
      rules: {
        sbbh: [
            {required: true, message: "无人机编号不可为空", trigger: ["blur", "change"]},
        ],
        sbmc: [
            {required: true, message: "无人机名称不可为空", trigger: ["blur", "change"]},
        ],
        fxms: [
            {required: true, message: "飞行模式不可为空", trigger: ["blur", "change"]},
        ],
        fxlx: [
            {required: true, message: "飞行路线不可为空", trigger: ["blur", "change"]},
        ],
        fxlc: [
            {required: true, validator: validateLogoFxlc, trigger: ["blur", "change"]},
        ],
        fxsj: [
          {required: true, validator: validateLogoFxsj, trigger: ["blur", "change"]},
        ],
        zxr: [
          {required: true, message: "执行人不可为空", trigger: ["blur", "change"]},
        ],
        rwrq: [
          {required: true, message: "任务日期不可为空", trigger: ["blur", "change"]},
        ],
      },
      formTitle: "",
      messageBox: false,
      showFlag: false,
      value: true
    };
  },
  mounted() {
    this.getList();
    this.getWrj();
    this.getLDXX()
  },
  methods: {
    // 请求无人机编号、名称
    getWrj() {
      this.$get("/wrjgl/getWrjxxs").then((res) => {
        if (res.data.state == "success") {
          this.optionListBH = []
          res.data.data.forEach(item => {
            let BH = {
              id: '',
              bh: ''
            }
            BH.id = item.id
            BH.bh = item.wrjbh
            this.optionListBH.push(BH)
          });
        }
      });
    },
    sbbhChange(row) {
      this.$get("/wrjgl/getWrjxxs").then(res => {
        if (res.data.state == "success") {
          let resList = res.data.data.filter(item => item.wrjbh === row)
          let MC = resList[0].wrjmc
          this.$nextTick(() => {
            this.$set(this.addForm, 'sbmc', MC)
          })
        }
      })
    },
    sbbhClear() {
      this.addForm.sbmc = ''
      this.getWrj()
    },
    getLDXX() {
      this.$get("/ldxx/page").then((res) => {
        if (res.data.state == "success") {
          this.addForm.fxlx = ''
          res.data.data.forEach(item => {
            let MC = {
              id: '',
              mc: ''
            }
            MC.id = item.id
            MC.mc = item.ldmc
            this.optionListLX.push(MC)
          });
        }
      });
    },
    //请求列表数据
    getList() {
      this.$get("/flylog/page", {
        page: this.page,
        size: this.size,
      }).then((res) => {
        if (res.data.state == "success") {
          //console.log("获取飞行记录列表===>", res)
          this.tableData = res.data.data;
          this.total = res.data.size;
        }
      });
    },
    // 编辑
    edits(row) {
      this.showFlag = true;
      this.ids = row.id;
      this.formTitle = "编辑";
      this.messageBox = true;
      row.fxlx = row.fxlx.split(',')
      this.addForm = JSON.parse(JSON.stringify(row));
    },
    // 删除
    deletes(id) {
      // 弹出提示是否删除
      this.$confirm("此操作将永久删除该记录, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$delete(`/flylog/delete/${id}`).then((res) => {
            if (res.data.state == "success") {
              this.$message.success("删除成功");
              this.getList();
            } else {
              this.$message.error("删除失败");
            }
          });
        })
        .catch((e) => e);
    },
    // 确认提交
    saveForm(formName) {
      // let id = this.ids;
      this.addForm.id = this.ids;
      let url = '/flylog/save';
      if(this.formTitle == '新增'){
        delete this.addForm.id;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.addForm.fxlx = this.addForm.fxlx.join(',')
          this.$postJSON(url, this.addForm).then((res) => {
            if (res.data.state == 'success') {
              if(this.formTitle == '新增'){
                this.$message.success('添加成功')
              }else{
                this.$message.success('修改成功')
              }    
              this.messageBox = false
              this.getList();
            } else {
              this.$message.error(res.data.msg)
              this.messageBox = false
            }
          })
        } else {
          // //console.log('error submit!!');
          return false;
        }
      });
    },
    // 新增
    addOption() {
      this.formTitle = "新增";
      this.messageBox = true;
      this.showFlag = true;
      this.$nextTick(() => {
        this.addForm = {};
        this.getWrj()
        this.$refs.addForm.resetFields();
      });
    },
    search() {
      this.page = 1
      this.getList();
    },
    handleSizeChange(val) {
      this.size = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.page = val;
      this.getList();
    }
  },
};
</script>

<style scoped lang="less">
@import "../../../style/backTable.less";
</style>
<style scoped>
#content /deep/ .el-table th {
  background-color: rgba(240, 242, 245, 1) !important;
}

#content /deep/ .el-table {
  max-height: 78vh;
  overflow-y: scroll;
}

#content /deep/ .el-table::-webkit-scrollbar {
  display: none;
}

.addAlaForm /deep/ .el-select {
  width: 100% !important;
}

.dialog-footer {
  height: 5vh;
  text-align: center;
}

.dialog-footer /deep/ .el-button, .el-button--info {
  margin-left: 10px !important;

}

.dialog-footer /deep/ .el-button--info {
  background-color: transparent !important;
  color: #000;
}

.title /deep/ .el-input__inner {
  height: 33px !important;
}
.title .wurenjiguanli {
  font-size: 4vh;
}

.drawerBox /deep/ .msg {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 1vh 1vw;
}

</style>


